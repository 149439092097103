import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { SpinnerCircular } from 'spinners-react'
import HubspotForm from 'react-hubspot-form'

import DocumentCard from '../Common/DocumentCard';
import DocumentCardsContainer from '../Common/DocumentCardsContainer';
import PortalVideoNew from '../Common/PortalVideoNew';
import PortalVideoUpdateNew from '../Common/PortalVideoUpdateNew';

const FormAssemblyEmbed = loadable(() =>
  pMinDelay(import('../Common/FormAssemblyEmbed'), 2000),{
    fallback:
      <div className="flex flex-col justify-center items-center mt-6">
        <SpinnerCircular color="#bb0000" secondaryColor="#FEF2F2" size="70" />
        <h2 className="text-center text-sm italic mt-3">Loading Form</h2>
      </div>
  }
)

const DPPSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query DPPSectionUpdateQuery {
      prismicSalesSupportDocumentsProductCollateralDpp {
        data {
          section_title
          form_type
          form_id
          form_section_title
          form_section_description
          video_type
          wistia_video_id
          vimeo_video_id
          vimeo_video_title
          pdf_files_list {
            pdf_file_title
            pdf_download_link {
              url
            }
            coming_soon
          }
        }
      }
    }
  `);

  const doc = data.prismicSalesSupportDocumentsProductCollateralDpp

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6">
        <h2 className="text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold">
          {doc.data.section_title}
        </h2>
        <p className="text-base md:text-xl text-gray-800 mt-6">The Dental Protection Plan (DPP) is a limited warranty program modeled after those used in other industries and applied to specific dental products.</p>
      </div>

      <DocumentCardsContainer containerTitle="Downloadable Documents">
        {doc.data.pdf_files_list.map(item => {
          return (
            item.coming_soon
            ?
            <DocumentCard
              cardTitle={item.pdf_file_title}
              internalLink
              buttonComingSoon
            />
            :
            <DocumentCard
              cardTitle={item.pdf_file_title}
              buttonLink={item.pdf_download_link.url}
            />
          )
        })}
      </DocumentCardsContainer>

      <div className="w-full max-w-screen-lg mt-16 mb-6">
        <h2 className="text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold">
          {doc.data.form_section_title}
        </h2>
        <p className="text-base md:text-xl text-gray-800 mt-6">{doc.data.form_section_description}</p>
      </div>

      {doc.data.form_type === "Form Assembly"
        ?
        <div className="max-w-screen-md bg-white mt-16 shadow-md rounded-md pt-2 pb-12">
          <FormAssemblyEmbed formId={doc.data.form_id} mdWidth="300" smWidth="330" xsWidth="280" height="600" />
        </div>
        : doc.data.form_type === "HubSpot"
        ?
        <div className="max-w-screen-md bg-white mt-16 shadow-md rounded-md p-6">
          <HubspotForm
            portalId='23182726'
            formId={doc.data.form_id ? doc.data.form_id : 'dfa38579-9c57-4e76-8e3d-5fd33b0f232e'}
            loading={<div className='italic'>Loading form...</div>}
          />
        </div>
        :
        null
      }

      {doc.data.video_type === 'Vimeo' || doc.data.video_type === 'Wistia'
        ?
        <div className="w-full max-w-screen-lg grid grid-cols-1 gap-6 xl:gap-16 mt-8 xl:mt-16">
          {doc.data.video_type === 'Vimeo'
            ?
            <PortalVideoNew
              videoID={doc.data.vimeo_video_id}
              videoTitle={doc.data.vimeo_video_title}
              withoutInfo
            />
            :
            doc.data.video_type === 'Wistia'
            ?
            <PortalVideoUpdateNew 
              videoID={doc.data.wistia_video_id} 
              videoTitle={doc.data.vimeo_video_title} 
              withoutInfo
            />
            :
            null
          }
        </div>
        :
        null
      }
    </>
  )
};

export default DPPSectionUpdate;
